
















import { Component, Vue, Prop } from "vue-property-decorator";
import { clearFilter } from "@/utils/base";
@Component({})
export default class Login extends Vue {
  @Prop()
  private keyText: any;
  private curTab: any = {};
  private get href() {
    return window.location.href;
  }
  private get nav() {
    const data: any = {
      sizhenNav: [
        {
          label: "中医影像",
          value: "中医影像",
          isShow: true,
          path: "/main/datacenter/sizhen-yingxiang",
          activePath: "/main/datacenter/sizhen-yingxiang",
        },
        {
          label: "体质辨识",
          value: "体质辨识",
          isShow: true,
          path: "/main/datacenter/sizhen-tizhi",
          activePath: "/main/datacenter/sizhen-tizhi",
        },
        {
          label: "慢病管理",
          value: "慢病管理",
          isShow: true,
          path: "/main/datacenter/sizhen-diseases",
          activePath: "/main/datacenter/sizhen-diseases",
        },
        {
          label: "辅助诊疗",
          value: "辅助诊疗",
          isShow: true,
          path: "/main/datacenter/sizhen-zhenliao",
          activePath: "/main/datacenter/sizhen-zhenliao",
        },
        {
          label: "心理测评",
          value: "心理测评",
          isShow: true,
          path: "/main/datacenter/sizhen-psychology",
          activePath: "/main/datacenter/sizhen-psychology",
        },
        {
          label: "健康养生",
          value: "健康养生",
          isShow: true,
          path: "/main/datacenter/sizhen-health",
          activePath: "/main/datacenter/sizhen-health",
        },
        {
          label: "病例数据",
          value: "病例数据",
          isShow: true,
          path: "/main/datacenter/sizhen-case",
          activePath: "/main/datacenter/sizhen-case",
        },
      ],
      hisNav: [
        {
          label: "门诊",
          value: "门诊",
          isShow: true,
          path: "/main/datacenter/his/his-menzhen",
          activePath: "/main/datacenter/his/his-menzhen",
        },
        {
          label: "住院",
          value: "住院",
          isShow: true,
          path: "/main/datacenter/his/his-zhuyuan",
          activePath: "/main/datacenter/his/his-zhuyuan",
        },
        {
          label: "检验",
          value: "检验",
          isShow: true,
          path: "/main/datacenter/his/his-jianyan",
          activePath: "/main/datacenter/his/his-jianyan",
        },
        {
          label: "检查",
          value: "检查",
          isShow: true,
          path: "/main/datacenter/his/his-jiancha",
          activePath: "/main/datacenter/his/his-jiancha",
        },
        // {
        //   label: "处方",
        //   value: "处方",
        //   isShow: true,
        //   path: "/main/datacenter/his/his-chufang",
        //   activePath: "/main/datacenter/his/his-chufang",
        // },
      ],
    };
    return data;
  }
  private get systemInfo() {
    return this.$store.state.systemInfo;
  }
  private changeOpen(item: any) {
    item.open = !item.open;
  }
  private goRouter(item: any) {
    if (!item.path) {
      return;
    }
    this.curTab = item;
    clearFilter();
    this.$router.push(item.path);
  }
  private mounted() {}
}
