












import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Class extends Vue {
  private get patient() {
    return this.$store.state.patient;
  }
  private goBack(){
    this.$router.push('/main/datacenter/his/his-list')
  }
}
